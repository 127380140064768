.image-view-modal {
    width: 95vw;
    height: 70vh;
}

.fa-solid.fa-x {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #ffffff;
    border: #ffffff;
}

.fa-solid.fa-x:hover {
    cursor: pointer;
}

.main-view-img {
    height: 50vh;
    width: 500px;
    object-fit: contain;
}

.img-modal-container {
    justify-self: center;
    align-content: center;
    height: 100%;
    display: grid;
    gap: 150px;
    justify-content: center;
    grid-template-areas:
                "main-img details";
}

.product-details-container {grid-area: details;}


.side-imgs-container {
    display: grid;
    justify-self: start;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2px;
}

.product-details-container {
    width: 280px;
    height: 80px;
}

.img-mod-descript {
    color: #111111
}

.img-mod-brand {
    font-size: 12px;
    color: #767676;
}
