.delete-form {
    display: grid;
    justify-content: center;
    margin: 40px;
}

.delete-confirm {
    font-size: 20px;
}

.delete-buttons {
    padding-top: 30px;
}

.delete-title {
    font-size: 25px;
}

.cancel-button {
    background-color: #ffffff;
    border: 1px solid #dde0e0;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(15,17,17,.15);
    margin-left: 10px;
}

.btn-txt {
    margin: 5px;
}

.cancel-button:hover {
    cursor: pointer;
}

.delete-button {
    background-color: #ffd815;
    border: 1px solid #ffd815;
    box-shadow: 0 2px 5px rgba(15,17,17,.15);
    border-radius: 10px;
}

.delete-button:hover {
    cursor: pointer;
}
