.delete-modal {
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #dfe2e2;
    box-shadow: 0 2px 5px rgba(15,17,17,.15);
}

.delete-modal:hover {
    cursor: pointer;
}

.btn-main {
    background-color: #ffffff;
    padding: 0px;
    border: #ffffff;
}
