/* Container for the slider */
.slider-container {
    display: block;
    justify-self: center;
    overflow: hidden;
    width: 80vw;
    height: 80vh;
    position: relative;
}

/* Slider image container */
.slider-img-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

/* Slider image */
.slider-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 1;
}

/* Fading effect at the bottom of the images */
.slider-img-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #e4e6e6 100%);
    pointer-events: none;
    z-index: 2;
}

/* Slider Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 40%;
    z-index: 3;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    transform: translate(0, -50%);
    background-color: transparent;
    cursor: pointer;
    border: none;
    outline: none;
}

.slick-prev:before,
.slick-next:before {
    font-size: 24px;
    color: white;
    opacity: 1;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.slick-prev {
    left: 20px;
}

.slick-next {
    right: 20px;
}

.slick-prev:before {
    content: '<';
    font-size: 70px;
}

.slick-next:before {
    content: '>';
    font-size: 70px;
}
