.review-mod-page {
    display: grid;
    width: 95vw;
    height: 70vh;
}

.review-modal-container {
    display: grid;
    align-self: center;
    justify-self: center;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 70%;
    height: 70%;
}


.fa-solid.fa-x {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #ffffff;
    border: #ffffff;
}

.fa-solid.fa-x:hover {
    cursor: pointer;
}

.owner-info-mod {
    display: inline-flex;
}

.profile-img-mod {
    display: flex;
    align-self: center;
    height: 30px;
    width: 30px;
}

.rev-owner-name {
    display: flex;
    align-self: center;
}

.rev-img-container {
    display: grid;
    gap: 10px;

}

.area1,
.area2 {
    height: 70%;
    width: 70%;
}

.rev_currImg {
    object-fit: contain;
    height: 80%;
    width: 80%;
}

.review-img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border: 1px solid black;
    border-radius: 5px;
    padding: 2px
}

.review-img:hover {
    box-shadow: 0px 0px 3px 3px lightblue;
}

.review-img:hover,
.rev_currImg:hover {
    cursor: pointer;
}

.stars-header-mod {
    display: inline-flex;
}
