.cat-store-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;
}

.category-banner {
    width: 90%;
    padding-bottom: 20px;
    padding-top: 10px;
}


.cat-product-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 1%;
    justify-content: center;
}

.cat-each-container {
    display: flex;
    flex-direction: column;
    width: 200px;
}

.cat-product-details {
    display: flex;
    flex-direction: column;
}

.cat-product-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
}

.price-rating {
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 10px;
}



.cat-price {
    display: flex;
    align-items: center;
}
