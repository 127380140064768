.form {
    display: grid;
    justify-content: center;
    margin-bottom: 50px;
}

.logo {
    height: 40px;
    margin-bottom: 3px;
    margin-top: 20px;
}

.logo:hover {
    cursor: pointer;
}

.img {
    display: grid;
    padding-bottom: 15px;
    justify-content: center;
}

.form-content {
    display: grid;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    border: 1px solid #ddd;
    width: 300px;
    border-radius: 5px;
    padding: 20px;
}

.header {
    font-size: 30px;
    margin: 0px;
}

.create-account {
    display: grid;
    justify-content: center;
    width: 300px;
    margin: 0;
    color: #767676;
}

.continue-button {
    background: -webkit-linear-gradient(top, #f4dc9a, #eec962);
    border-color: #767676;
    height: 30px;
    border-radius: 3px;
    border-width: 1px;
}

.continue-button:hover {
    cursor: pointer;
}

h3 {
    margin: 2px;
    font-size: 12px;
    font-weight: bold;
}

.input {
    background-color: #eaf0fe;
    border: 1px solid #a6a6a6;
    border-radius: 3px;
    width: 100%;
}

.input-fields {
    display: grid;
    margin-top: 10px;
}

.after-form-area {
    justify-self: center;
    position: relative;
    width: 345px;
    margin: 0;
}

.after-form-area p {
    color: #767676;
    font-size: 13px;
    margin-top: 40px;
}

.after-form-area p::before,
.after-form-area p::after {
    content: " ";
    display: block;
    width: 120px;
    height: 1px;
    background: #e7e7e7;
    position: absolute;
    top: 40%;
}

.after-form-area p::before {
    left: 0
}

.after-form-area p::after {
    right: 0
}

.button-div {
    display: grid;
    justify-content: center;
}

.demo-user-container {
    display: inline-flex;
}

.signup-btn {
    padding: 5px;
    margin-bottom: 20px;
    width: 345px;
    border: 1px solid #a2a6ac;
    border-radius: 3px;
}

.signup-btn:hover {
    cursor: pointer;
}

.after-form-header {
    display: grid;
    justify-content: center;
}

.demoUser {
    margin-left: 3px;
}

.demoUser:hover {
    cursor: pointer;
    text-decoration: underline;
}
