.form-pictures {
    display: grid;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    border: 1px solid #ddd;
    width: 350px;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 50px;
}

.input-file {
    display: grid;
    justify-content: center;
    margin-top: 2px;
}


.create-botton {
    margin-top: 10px;
    background-color: #ffd815;
    border: 1px solid #ffd815;
    border-radius: 5px;
}

.btn-txt {
    margin: 5px;
}


.img-form-sub-header {
    font-size: 15px;
}

#file {
    display: none;
}

.upload-container {
    display: grid;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #dde0e0;
    border-radius: 5px;
    width: auto;
}

.upload-txt {
    margin: 5px;
    font-size: 14px;
}

.img-btn:hover {
    cursor: pointer;
}

.create-botton:hover {
    cursor: pointer;
}

.btn-txt {
    margin: 5px;
}
