.footer {
    position: fixed;
    background-color: #282f3e;
    width: 100%;
    height: 70px;
    bottom: 0;
}

.name {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}

.link-icons {
    display: flex;
    justify-content: center;
    padding-top: 5px;
}

.github-icon {
    margin-right: 10px;
}

.fa-brands {
    color: white;
}

.footer-icon:hover {
    cursor: pointer;
}
