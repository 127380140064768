.error-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}

.error-img {
    margin-top: 60px;
    margin-bottom: 60px;
}

.error-body-msg {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-top: 20px;
}

.error-link {
    text-decoration: none;
    color: blue;
    margin-left: 5px;
}
