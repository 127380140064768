.hidden {
  display: none;
}

.nav-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  background-color: #141921;
  margin: 0px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.search-results {
  position: absolute;
  top: 55px;
  left: 20.8%;
  background-color: #ffffff;
  color: #262f3e;
  max-height: 200px;
  width: 29vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.profile-dropdown {
  position: absolute;
  z-index: 1000;
  width: 9vw;
}

.section1 {
  margin-left: 20px;
  flex: 0 0 auto;
  padding: 5px;
}

.section2 {
  display: flex;
  flex: 0 0 auto;
  padding: 5px;
}

/* .section3 {
  display: flex;
  flex: 1 1 auto;
  position: relative;
} */

.section4 {
  flex: 0 0 auto;
  padding: 5px;
}

.section5 {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 20px;
  padding: 5px;
}


.hidden {
  display: none;
}

.nav-logo {
  height: 35px;
}

.section1:hover,
.section2:hover,
.section4:hover,
.section5:hover {
  border: 1px solid white;
}

.geo-loc-icon {
  height: 30px;
}

.category-bar {
  display: flex;
  height: 40px;
  background-color: #262f3e;
}

.category-txt {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 2px;
  color: #ffff;
  margin-left: 10px;
}

.category-txt:hover {
  cursor: pointer;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px;
}

/* .search-container {
  position: relative;
  display: flex;
  align-items: center;
  left: 70px;
}
::-webkit-scrollbar {
  width: 20px;
} */

@media (min-width: 1730px) {
  .search {
    width: 1150px;
    height: 45px;
  }
  .search-results {
    width: 1150px;
  }
}

@media (max-width: 1729px) {
  .search {
    width: 700px;
    height: 45px;
  }
  .search-results {
    width: 700px;
  }
}

@media (max-width: 1530px) {
  .search {
    width: 750px;
    height: 45px;
  }
  .search-results {
    width: 750px;
  }
}

@media (max-width: 1330px) {
  .search {
    width: 450px;
    height: 45px;
  }
  .search-results {
    width: 450px;
  }
}


.fa-magnifying-glass {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #febd69;
  color: #141921;
  height: 44.5px;
  width: 30px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.cart-icon-container {
  position: relative;
  width: fit-content;
}

.cart-icon {
  height: 35px;
  width: 35px;
  /* position: relative;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.cart-num {
  position: absolute;
  color:#e98613;
  top: 30%;
  left: 55%;
  transform: translate(-50%, -50%);
  font-size: 17px;
  padding: 4px 8px;
  border-radius: 50%;
}


.fa-caret-down {
  color: #a8acb2
}

.all-btn:hover,
.section1:hover,
.section3:hover,
.section4:hover,
.section5:hover,
.section2:hover {
  cursor: pointer;
}

.search-results:hover{
  cursor:pointer;
}

.search-card {
  padding: 10px;
  cursor: pointer;
  background-color: #ffffff;
  color:#262f3e;
  height: 20px;
  width: 100%;
  text-align: start;
  padding: 10px;
  cursor: pointer;
}

.search-none {
  position: absolute;
  background-color: rgb(24, 24, 24);
  top: 55px;
  left:22px;
  color: white;
  border: white solid 1px;
  width: 140px;
  height: 30px;
  text-align: center;
  padding-top: 15px;
  border-radius: 40px;
  overflow-y: hidden;
  z-index: 1000;
}

.all-btn {
  height: 47px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

/* .line-1,
.line-2 {
  margin-left: 15px;
} */
