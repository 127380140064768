.create-button {
    background: -webkit-linear-gradient(top, #f4dc9a, #eec962);
    border-color: #767676;
    height: 30px;
    border-radius: 3px;
    border-width: 1px;
    width: 305px;
}

.input {
    width: 300px;
}

.description {
    display: block;
    background-color: #eaf0fe;
    border: 1px solid #a6a6a6;
    border-radius: 3px;
    width: 300px;
    resize:vertical;
    overflow: hidden;
}

.form-content {
    padding-bottom: 30px;
}

.form {
    padding-bottom: 50px
}
