.body {
    display: grid;
    justify-content: center;
    background-color: #e4e6e6;
    min-height: 100vh;
}

.product-display-container {
    display: grid;
    position: relative;
    /* padding-right: 5%; */
    bottom: 130%;
    grid-template-areas:
        "container-0 container-1 container-2 container-3"
        "container-0 container-1 container-2 container-4";
    gap: 10px;
}


.product-details-main {
    display: grid;
    justify-items: space-around;
    margin-top: 10px;
}

.product-box {
    display: grid;
    justify-content: center;
    max-height: 420px;
    align-content: center;
    border: 1px solid #e7e7e7;
    border-radius: 2px;
    background-color: white;
}
.product-box.container-3,
.product-box.container-4 {
    height: 160px;
}

.product-box-img-0,
.product-box-img-1,
.product-box-img-2 {
    display: block;
    justify-self: center;
    /* width: 265px; */
    height: 150px;
    object-fit: contain;
}

.product-box-img-3,
.product-box-img-4 {
    display: block;
    justify-self: center;
    /* width: 100px; */
    height: 60px;
    object-fit: contain;
}


.product-box-img-0:hover,
.product-box-img-1:hover,
.product-box-img-2:hover,
.product-box-img-3:hover,
.product-box-img-4:hover {
    cursor: pointer;
}

.stock {
    height: 20px;
}

.in-stock {
    color: #017601;
    font-size: 12px;
    margin: 0;
}

.no-stock {
    color: #c25636;
    font-size: 12px;
}

.container-0 { grid-area: container-0; }
.container-1 { grid-area: container-1; }
.container-2 { grid-area: container-2; }
.container-3 { grid-area: container-3; }
.container-4 { grid-area: container-4; }

.product-name-main {
    margin: 0;
    width: 265px;
    height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.product-name-main:hover {
    cursor: pointer;
}

.add-to-cart {
    justify-self: center;
    background-color: #ffd815;
    border: 1px solid #ffd815;
    border-radius: 15px;
    width: 90px;
    height: 30px;
    box-shadow: 0 2px 5px rgba(15,17,17,.15);
}

.add-to-cart:hover {
    cursor: pointer;
    filter: brightness(95%);
}


/* @media (max-width: 1366px) {
    .product-display-container {
      width: 450px;
      bottom: 22%;
    }
    .product-box-img-0,
    .product-box-img-1,
    .product-box-img-2 {
        width: 150px;
        height: 150px;
    }
    .product-box-img-3,
    .product-box-img-4 {
        width: 80px;
        height: 70px;
    }
    .product-box {
        width: 200px;
    }
    .product-name-main {
        width: 180px;
        margin: 0;
    }
}

@media (max-width: 992px) {
    .product-display-container {
        width: 150px;
    }
    .product-box {
        width: 150px;
    }
      .product-box-img-0 {
        width: 265px;
        height: 150px;
    }
}

@media (min-width: 1367px) {
    .product-display-container {
      width: 300px;
      bottom: 22%
    }
    .product-box {
        width: 300px;
    }
    .product-box-img-3,
    .product-box-img-4 {
        width: 80px;
        height: 50px;
    }
} */
