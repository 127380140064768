.product-page {
    display: grid;
    justify-content: center;
    margin-bottom: 200px;
}

.main-container {
    display: grid;
    justify-content: center;
    grid-template-areas:
                "side-img main-img details cart"
                "reviews reviews reviews reviews";
    width: 70vw;
    margin: 20px;
}

.side-img-container { grid-area: side-img; }
.main-img-container { grid-area: main-img; }
.product-info-container { grid-area: details; }
.cart-container { grid-area: cart; }

.side-img-container {
    display: grid;
    align-self: start;
    margin-top: 20px;
}

.small-img {
    margin: 2px;
    border: 1px solid black;
    border-radius: 3px;
    width: 45px;
    height: 45px;
    object-fit: contain;
}

.small-img:hover {
    cursor: pointer;
    box-shadow: 0px 0px 3px 3px lightblue;
}

.small-img.selectedImg {
  box-shadow: 0px 0px 3px 3px lightblue;
}

.main-img-container {
    width: 300px;
    height: 300px;
}

.main-img {
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.main-img:hover {
    cursor: pointer;
}


.product-info-container {
    width: 50%;
}

.product-detail-description,
.product-detail-name {
    width: 370px;
}

.product-detail-description {
    margin-left: 10px;
}

.product-detail-name {
    border-bottom: 1px solid #eeefef;
    margin: 10px;
}

.about-item {
    font-size: 17px;
    border-top: 1px solid #eeefef;
    padding: 10px;
    width: 370px;
    margin-right: 10px;
    font-weight: bold;
}

.deets-in-stock {
    color: #067805;
    font-size: 20px;
}

.deets-cart-btn {
    margin-top: 10px;
}

.cart-container {
    display: grid;
    justify-content: center;
    margin-top: 20px;
    border: 1px solid #dfe2e2;
    border-radius: 10px;
    width: 250px;
}

.prod-cart-details {
    margin: 10px;
    width: 210px;
}

.buy-new {
    font-weight: 600;
}

.prod-price {
    margin-top: 5px;
    margin-left: 8px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 25px;
}

.selection:hover {
    cursor: pointer;
}

.deets-add-to-cart {
    justify-self: center;
    background-color: #ffd821;
    border: 1px solid #ffd821;
    border-radius: 15px;
    padding: 7px;
    width: 100%;
}

.deets-add-to-cart:hover {
    cursor: pointer;
    filter: brightness(95%);
}

.brand-txt {
    font-weight: bold;
    margin-right: 3px;
    margin-left: 10px;
}

.deets-brand-container {
    display: inline-flex;
}

.review-container {
    display: flex;
    grid-area: reviews;
    gap: 65px;
    width: 100%;
    border-top: 1px solid #dfe2e2;
    margin-top: 20px;
}

.review_header {
    margin-left: 5px;
    font-weight: 600;
}

.user-info {
    display: flex;
    align-items: center;
}

.review_owner {
    margin-left: 5px;
}

.profile-img {
    height: 25px;
    width: 25px;
}

.stats-header {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 5px;
}

.stats-subheader {
    margin-top: 5px;
}

.review-btn-txt {
    margin: 5px;
    font-size: 12px;
}

.create-review-btn,
.review-edit-btn,
.review-delete-btn {
    background-color: #ffffff;
    border: 1px solid #dfe2e2;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(15,17,17,.15);
}

.review_details {
    margin-top: 10px;
}

.create-review-btn {
    padding: 10px;
}

.create-review-btn:hover {
    cursor: pointer;
    filter: brightness(95%);
}


.review-edit-btn:hover {
    cursor: pointer;
}

.rev-img-container {
    display: grid;
    grid-template-areas:
        "img1 img2 img3 img4 img5";
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.review_img.0 { grid-area: img1 }
.review_img.1 { grid-area: img2 }
.review_img.2 { grid-area: img3 }
.review_img.3 { grid-area: img4 }
.review_img.4 { grid-area: img5 }

.review_img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    border: 1px solid #dfe2e2;
    border-radius: 5px;
}

.review_img:hover {
    cursor: pointer;
    box-shadow: 0px 0px 3px 3px lightblue;
}

.fa-pen-to-square {
    background-color: #ffffff;
    margin-left: 5px;
}

.fa-pen-to-square:hover {
    cursor: pointer;
}


.star-review.fa-star {
    color: #e99e5d;
    font-size: 15px;
}

.star-review.fa-star:hover {
    cursor: default;
}

.star-review.fa-star.filled2 {
    color: #fea41c;
}

.review-section {
    margin-top: 10px;
    border-bottom: 1px solid #dfe2e2;
}
