/* TODO Add site wide styles */
body {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px;
    margin-bottom: 0px;
    padding: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    width: 1em;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}
