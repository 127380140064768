.main-section {
    background-color: #ffff;
    height: fit-content;
    width: 600px;
    margin: 20px;
}

.cart-item-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    align-items: center;
    margin: 20px;
}

.cart-edit {
    display: flex
}

.header-box {
    border-bottom: 1px solid #eee;
    padding-bottom: 7px;
    margin: 20px 10px 10px 20px;
}

.cart-header {
    font-size: 27px;
}

.cart-img-container {
    display: flex;
    justify-content: center;
}

.cart-img-container:hover {
    cursor: pointer;
}

.cart-prev-img {
    height: 180px;
    width: 180px;
    object-fit: contain;
}

.price,
.brand {
    margin: 0;
}

.subtotal-box {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #eee;
    padding-top: 7px;
    margin: 20px 10px 10px 20px;
}

.subtotal {
    font-size: 15px;
    margin-right: 3px;
}

.total {
    font-weight: 600;
}

#mySelect {
    background-color: #f0f2f2;
    border-radius: 5px;
    margin-right: 3px;
    box-shadow: 0 2px 5px rgba(15,17,17,.15);
    border-color: #D5D9D9;
}

.prod-deets-delete-btn {
    background-color: #ffff;
    color: #0F1111;
    border: none;
}

.prod-deets-delete-btn:hover,
.prod-deets-name:hover {
    cursor: pointer;
    text-decoration: underline;
    color: blue;
}


@media (max-width: 1366px) {
    .main-section {
        margin-bottom: 90px;
    }
}

@media (max-width: 992px) {

}

@media (min-width: 1367px) {

}
