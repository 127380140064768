.profile-button {
    background-color: transparent;
    border: none;
}

.profile-button:hover {
    cursor: pointer;
}

.logout-button,
.store-button,
.log-in-button,
.signup-button {
    background-color: transparent;
    border: none;
}

.logout-button:hover,
.store-button:hover,
.log-in-button,
.signup-button {
    cursor: pointer;
}

.signup-button {
    width: 60px;
    margin: 0;
}

.profile-dropdown {
    display: grid;
    border-radius: 5px;
    border: 1px solid #efefef;
    justify-items: start;
    background-color: #ffffff;
    color: black;
}

.drop-down-header {
    font-size: 12px;
    padding: 5px;
}

