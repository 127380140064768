.store-container {
    display: grid;
    justify-content: center;
    max-width: 70%;
    margin: 0 auto;
}

.header-container {
    display: flex;
    align-items: center;
}

.product-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 90px;
}

.store-header {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 27px;
}

.sell-more-container {
    align-self: center;
    padding: 10px;
}

.sell-more-btn {
    border-right: 0;
    border-bottom: 0;
    border-top: 0;
    font-size: 15px;
    background-color: white;
}

.sell-more-btn:hover,
.product-name:hover {
    cursor: pointer;
    text-decoration: underline;
    color: blue;
}

.product-name {
    height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.img-container {
    display: grid;
    justify-content: center;
    align-content: center;
    border: 1px solid #e7e7e7;
    border-radius: 2px;
    width: 200px;
    height: 300px;
}

.user-store-img {
    display: grid;
    justify-self: center;
    width: 170px;
    height: 270px;
    object-fit: contain;
}

.product-details {
    display: grid;
    margin-top: 10px;
    width: 200px;
    height: 70px;
}

.product-name:hover,
.sell-more-btn:hover {
    text-decoration: none;
    color: #0f1111;
}

.edit-btn:hover,
.fa-trash-can:hover,
.img-container:hover {
    cursor: pointer;
}

.edit-btn {
    background-color: #ffffff;
    border: 1px solid #dfe2e2;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(15,17,17,.15)
}

.buttons {
    margin-top: 10px;
}

.edit-btn {
    margin-right: 2px;
}

.review-count {
    height: 20px;
}
