.signup-input {
    display: grid;
    border: 1px solid #949494;
    border-radius: 3px;
    width: 98%;
    height: 20px;
}

.form-content-signup {
    display: grid;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    border: 1px solid #ddd;
    width: 350px;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 50px;
}

.input-section {
    margin: 2px;
}
